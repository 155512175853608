.animation-box {
  display: block;
  position: relative;
  height: 30px;
  width: 30px;
}

.script-box {
    will-change: width, height, left, top;
    position: absolute;
    top: 0;
    left: 0;
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in; 
  }

  @keyframes spin {
      from {
          transform:rotate(0deg);
      }
      to {
          transform:rotate(360deg);
      }
  }


.script-box-b {
  will-change: width, height, left, top;
  position: absolute;
  animation-name: spinb;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in; 
}


@keyframes spinb {
  from {
      transform:rotate(0);
  }
  to {
      transform:rotate(-360deg);
  }
}