/* The side navigation menu */
.sidepanel {
    display: none;
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: absolute; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    right: 0;
    background-color: rgb(235, 236, 241); /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidepanel */
    padding: 6em 4em;
    border-left: 3px solid #cecece;
}


.roles-info-container {
    display: grid;
    grid-template-rows: 20% 90% 10%;
    grid-template-columns: 100%;
    grid-template-areas: "roles-header"
    "roles-body"
    "roles-footer";
}

.roles-header {
    display: grid;
    grid-area: roles-header;
    grid-template-rows: 100%;
    grid-template-columns: 80% 20%;
    grid-template-areas: "roles-title roles-close";
}

.roles-title {
  display: grid;
  align-items: center;
}

.sidepanel .roles-close {
  display: grid;
  align-self: center;
  justify-self: center;
}

.sidepanel .roles-close a {
  color: gray;
  font-size: 3em;    
}

.sidepanel .roles-close a:hover {
  color: #000;
}

.roles-body {
    display: grid;
    grid-area: roles-body;
}

.roles-footer {
    display: grid;
    grid-area: roles-footer;
    grid-template-rows: 100%;
    grid-template-columns: 20% 80%;
    grid-template-areas: "save-button .";
}

.permission-tile {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 50% 50%;
  grid-template-areas: "permission-name permission-value";
  padding: 1em 0;
  border-bottom: 1px solid #cecece;
}

.permission-name {
  display: grid;
  grid-area: permission-name;
  align-items: center;
}

.permission-value {
  display: grid;
  grid-area: permission-value;
  justify-content: center;
}
