@media only screen and (min-width: 300px) {
    .qip-card {
        display: grid;
        margin: 2em 0;
        grid-template-columns: 100%;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        grid-template-areas:
            "card-title"
            "card-name"
            "card-assets"
            "card-badge"
            "card-admin";
        height: 29em;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid rgba(194, 194, 194, 0.5);
        box-shadow: 0 2px 3px 0 rgba(94, 94, 94, 0.1);
        align-items: center;
        justify-content: center;
        transition: all .4s;
        cursor: pointer;
    }

    .card-details {
        display: grid;
        grid-template-rows: 50% 50%;
        grid-template-columns: 100%;
        grid-template-areas: 
        "card-details-header"
        "card-details-body";
        grid-row-gap: 2em;
    }

    .card-details-header {
        grid-area: card-details-header;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 100%;
        grid-template-areas: 
        "assets" 
        "type" 
        "total";

    }

    .card-details-body {
        grid-area: card-details-body;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 70% 30%;
        grid-template-areas: 
        "tables"
        "chart";
        grid-column-gap: 2em;
    }

    .card-details-body .tables {
        grid-area: tables;
        display: grid;
    }

    .card-details-body .tables .card-details-table {
        max-width: 21em;
        max-height: 30em;
    }

    .card-details-body .chart {
        grid-area: chart;
        display: grid;
        max-height: 40em;
    }
}

@media only screen and (min-width: 700px) {
    .qip-card {
        display: grid;
        margin: 1em 0;
        padding: 0 0 0 2em;
        grid-template-columns: 1fr .3fr .3fr .3fr .3fr .3fr;
        grid-template-rows: 100%;
        grid-template-areas: "card-title card-name card-chip card-badge card-admin card-delete";
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid rgba(194, 194, 194, 0.5);
        box-shadow: 0 2px 3px 0 rgba(94, 94, 94, 0.1);
        height: 4em;
        align-items: center;
        justify-content: center;
        transition: all .4s;
        cursor: pointer;
    }

    .qip-card .card:not(:last-child) { 
        border-right: 1px solid #cecece;
    }

    .card-details {
        display: grid;
        grid-template-rows: 30% 70%;
        grid-template-columns: 100%;
        grid-template-areas: 
        "card-details-header"
        "card-details-body";
        grid-row-gap: 2em;
    }

    .card-details-header {
        grid-area: card-details-header;
        display: grid;
        grid-template-rows: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: 
        "assets type total"
        ;
    }

    .card-details-header .card:not(:last-child) { 
        border-right: 1px solid #dedede;
    }

    .card-details-body {
        grid-area: card-details-body;
        display: grid;
        grid-template-columns: 70% 30%;
        grid-template-rows: 100%;
        grid-template-areas: 
        "tables chart";
        grid-column-gap: 2em;
    }

    .card-details-body .tables .card-details-table {
        max-width: 100%;
    }

    .card-details-body .tables {
        grid-area: tables;
        display: grid;
    }

    .card-details-body .chart {
        grid-area: chart;
        display: grid;
        max-height: 40em;
    }

    .card-movimientos-body {
        grid-area: card-details-body;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 100%;
        grid-template-areas: 
        "tables";
        grid-column-gap: 2em;
    }
}

.qip-card.active {
    border: 1px solid;
    border-color: gold;
    box-shadow: 0 15px 30px 0 rgba(94, 94, 94, 0.2);
    transform: scale(1.03);
}

.qip-card:hover {
    box-shadow: 0 15px 30px 0 rgba(94, 94, 94, 0.2);
    transform: scale(1.01);
}

/* .card-title {
    grid-area: card-title;
    display: grid;
    height: 100%;
    align-self: center;
    background-image: linear-gradient(-225deg, #22E1FF 0%, #1D8FE1 48%, #625EB1 100%);
    border-right: 1px solid #cecece;
    box-shadow: 5px 0 10px 0 rgba(101, 101, 101, 0.2);
    justify-content: center;
    align-items: center;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 0 2px 2px rgba(86, 83, 83, 0.5);
    font-size: 1.3em;
} */

.qip-card.disabled > .card-title {
    grid-area: card-title;
    display: grid;
    height: 100%;
    align-self: center;
    background-image: linear-gradient(25deg, #d4d4d4, #949494)!important;
    border-right: 1px solid #cecece;
    box-shadow: 5px 0 10px 0 rgba(101, 101, 101, 0.2);
    justify-content: center;
    align-items: center;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 0 2px 2px rgba(86, 83, 83, 0.5);
    font-size: 1.7em;
}

.card-name {
    grid-area: card-name;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    grid-template-areas:
        "card-name-header";
    align-items: center;
    justify-self: center;
    text-align: center;
}

.card-name-header {
    grid-area: card-name-header;
    display: grid;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 700;
    color: rgb(22, 121, 22);
}

.card-name-subtitle {
    grid-area: card-name-subtitle;
    display: grid;
    justify-content: center;
    color: #949494;
    text-transform: uppercase;
    font-size: .9em;
}

.card-chip {
    grid-area: card-chip;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 80% 20%;
    grid-template-areas:
        "card-assets-header"
        "card-assets-subtitle";
    align-items: center;
    justify-self: center;
}

.card-assets-header {
    grid-area: card-assets-header;
    display: grid;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 700;
}

.card-assets-subtitle {
    grid-area: card-assets-subtitle;
    display: grid;
    justify-content: center;
    color: #949494;
    text-transform: uppercase;
    font-size: .9em;
}

.card-badge {
    grid-area: card-badge;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 80% 20%;
    grid-template-areas:
        "card-badge-header"
        "card-badge-subtitle";
    justify-content: center;
    
}

.card-badge-header {
    grid-area: card-badge-header;
    display: grid;
    justify-content: center;
    align-items: center;
}

.card-badge-subtitle {
    grid-area: card-badge-subtitle;
    display: grid;
    justify-content: center;
    color: #949494;
    text-transform: uppercase;
    font-size: .9em;
}

.card-admin {
    grid-area: card-admin;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 80% 20%;
    grid-template-areas:
        "card-admin-logo"
        "card-admin-subtitle";
    justify-content: center;
}

.card-admin-logo {
    grid-area: card-admin-logo;
    display: grid;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.card-delete {
    grid-area: card-delete;
    display: grid;
    justify-content: center;
}

.qip-card.disabled > .card-admin .card-admin-logo {
    grid-area: card-admin-logo;
    display: grid;
    justify-content: center;
    align-items: center;
    margin: auto;
    filter: grayscale(100%);
}

.card-admin-subtitle {
    grid-area: card-admin-subtitle;
    display: grid;
    justify-content: center;
    color: #949494;
    text-transform: uppercase;
    font-size: .9em;
}

.card-total {
    grid-area: card-total;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 50% 50%;
    grid-template-areas: 
    "card-total-header"
    "card-total-subtitle"
    ;
    justify-content: center;
    
}

.card-total-header {
    grid-area: card-total-header;
    display: grid;
    font-size: 2em;
    color: #24864d;
    justify-content: center;
    align-items: center;
}

.card-total-subtitle {
    grid-area: card-total-subtitle;
    display: grid;
    justify-content: center;
    color: #949494;
    text-transform: uppercase;
    font-size: .9em;
}

.card-card-header {
    background-image: linear-gradient(60deg, #22E1FF 0%, #1D8FE1 48%, #625EB1 100%);
    padding-bottom: 1em;    
}

.card-card-title {
    margin-top: 0.5em;  
    color: white; 
}

#card-card-category {
    color: #ffffff;
    padding-bottom: 1em;
    margin-top: -1em;
}

/* 
    MODAL
*/

.card-modal-header {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    grid-template-areas: "title details";
    padding: 0px 6em;
    align-items: center;
    height: 7em;
    box-shadow: 0 13px 10px rgba(0,0,0,0.1);
}

.card-modal-header .title {
    display: grid;
    grid-area: title;
    font-size: 2.5em;
    color: #656565;
    font-weight: 500;
    letter-spacing: 1px;
    text-shadow: 0 4px 5px rgba(154, 154, 154, 0.3);

}

.card-modal-header .details {
    display: grid;
    grid-area: details;
    grid-template-columns: 100%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
        "total"
        "tipo";
    height: 100%;
    text-align: right;
    align-items: center;
}

.card-modal-header .details .total {
    display: grid;
    grid-area: total;
    font-size: 1.5em;
    color: #258c20;
    font-weight: 500;
}

.card-modal-header .details .tipo {
    display: grid;
    grid-area: tipo;
    font-size: 1em;
    color: #7d7373ad;
    text-transform: uppercase;
}

.card-details-header .assets {
    grid-area: assets;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 100%;
    grid-template-areas: 
    "icon"
    "value" 
    "name";
    justify-content: center;
    align-self: center;
}

.card-details-header .assets .icon {
    display: grid;
    grid-area: icon;
    justify-content: center;
    align-self: end;
    color: #236d9d;
    font-size: 2em;
}

.card-details-header .assets .value {
    display: grid;
    grid-area: value;
    justify-content: center;
    align-self: center;
    font-size: 2em;
}

.card-details-header .assets .name {
    display: grid;
    grid-area: name;
    justify-content: center;
    align-self: start;
    text-transform: uppercase;
    color: gray;
}

.card-details-header .type {
    grid-area: type;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 100%;
    grid-template-areas: 
    "icon"
    "value" 
    "name";
    justify-content: center;
    align-self: center;
}

.card-details-header .type .icon {
    display: grid;
    grid-area: icon;
    justify-content: center;
    align-self: end;
    color: #9d8823;
    font-size: 2em;
}

.card-details-header .type .value {
    display: grid;
    grid-area: value;
    justify-content: center;
    align-self: center;
    font-size: 2em;
}

.card-details-header .type .name {
    display: grid;
    grid-area: name;
    justify-content: center;
    align-self: start;
    text-transform: uppercase;
    color: gray;
}

.card-details-header .total {
    grid-area: total;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 100%;
    grid-template-areas: 
    "icon"
    "value" 
    "name";
    justify-content: center;
    align-self: center;
}

.card-details-header .total .icon {
    display: grid;
    grid-area: icon;
    justify-content: center;
    align-self: end;
    color: #11940b;
    font-size: 2em;
}

.card-details-header .total .value {
    display: grid;
    grid-area: value;
    justify-content: center;
    align-self: center;
    font-size: 2em;
}

.card-details-header .total .name {
    display: grid;
    grid-area: name;
    justify-content: center;
    align-self: start;
    text-transform: uppercase;
    color: gray;
}

