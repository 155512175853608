.edit-risk {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    cursor: pointer;
    z-index: 2000;
    transition: all .7s;
}

.edit-risk:hover {
    background-color: white;
    opacity: .5;
}