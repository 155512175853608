.horiz-nav-container {
    padding: 0 10em;
    margin-bottom: 2em;
}

.horiz-nav {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
}

.horiz-nav li {
    width: 100%;
}

.horiz-nav button {
    width: 100%;
    font-size: 1.6em;
    font-weight: 500;
    background: transparent;
    color: #bdc3c7;
    border-bottom: 4px solid #bdc3c7;
    border-radius: 0px;
    transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.horiz-nav button:hover {
    color: #f96332;
    border-bottom: 4px solid #bdc3c7;
    background: transparent;
    box-shadow: none!important;
}

.horiz-nav button:focus {
    border-bottom: 4px solid #bdc3c7;
    background: transparent!important;
}

.horiz-nav a.active button {
    color: #2c3e50;
    border-bottom: 4px solid #f96332;
}

.users-container {
    margin: 0 10em;
}

.role-users-card {
    display: grid;
    padding: 2em;
    height: 150px;
    grid-template-areas: "header"
    "subheader"
    "body";
    grid-template-columns: 100%;
    grid-template-rows: 25% 25% 50%;
}

.header {
    display: block;
    grid-area: header;
    grid-template-areas: ". .";
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
}

.header:not(:first-child) {
    justify-self: end;
} 
