.simple-trans-main {
    display: block;
    position: absolute;
    top: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

}

.simple-trans-main>div {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    will-change: transform, opacity;
}

.simple-trans-main .content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}