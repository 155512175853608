@media screen and (min-width:320px) {
    .image2 {
        background-position: center;
        background-size: contain;
        background-repeat: initial;
        overflow: hidden;
        height: 570px;
        width: 100%;
        position: relative;
        margin: auto;
       /* border-radius: 10px;
        box-shadow: 5px 5px 10px 0px;*/
    }
    .txtonb1 {
        color: black ;
        position: relative; 
        font-size: small;
        top: 52%;
        padding-left: 4em;
        padding-right: 4em; 
        text-align: center;
    }
    .txtonb2 {
        color: gray ;
        font-size: smaller;
        position: relative;
        top: 54%;
        padding-left: 2em;
        padding-right: 2em; 
        text-align: center;
    }
    .wizard-container .card-wizard .tab-content{
        padding: 0px 0;
    }
    .wizard-container .card-wizard .card-footer .btn-wd {
        min-width: 100px;
    }
    .wizard-container .card-wizard .card-title {
        font-size: 1.2em;
        line-height: 1em;
        font-weight: 400;
        text-transform: none;
    }
    .wizard-container .card-wizard .card-title + .description {
        font-size: 15px;
        margin-bottom: 15px;
    }
    .wizard-container .card-wizard .nav-pills .nav-item .nav-link {
        padding-top: 5px;
        padding-bottom: 0px;
        background-color: transparent;
        box-shadow: none;
    }
    .wizard-container .nav-pills .nav-item .nav-link {
        text-align: center;
        padding: 0px 0px;
        min-width: 100px;
        font-weight: 400;
    }
}

@media screen and (max-width:480px) and (min-width:325px) {
    .image2 {
        background-position: center;
        background-size: contain;
        background-repeat: initial;
        overflow: hidden;
        height: 640px;
        width: 100%;
        position: relative;
        margin: auto;
        /*border-radius: 10px;
        box-shadow: 5px 5px 10px 0px;*/
    }
    .txtonb1 {
        color: black ;;
        position: relative; 
        top: 54%;
        padding-left: 4em;
        padding-right: 4em; 
        text-align: center;
    }
    .txtonb2 {
        color: slategray ;
        position: relative;
        top: 56%;
        padding-left: 2em;
        padding-right: 2em; 
        text-align: center;
    }
    .wizard-container .card-wizard .tab-content{
        padding: 0px 0;
    }
}
@media screen and (max-width:640px) and (min-width:480px) {
    .image2 {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
        height: 640px;
        width: 100%;
        position: relative;
        margin: auto;
       /* border-radius: 10px;
        box-shadow: 5px 5px 10px 0px;*/
    }
    .txtonb1 {
        color: black ;
        position: relative;
        top: 50%;
        padding-left: 5em;
        padding-right: 5em; 
        text-align: center;
    }
    .txtonb2 {
        color: slategray ;
        position: relative;
        top: 52%;
        padding-left: 3em;
        padding-right: 3em; 
        text-align: center;
    }
}
@media screen and (max-width:1024px) and (min-width:640px) {
    .image2 {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
        height: 640px;
        width: 65%;
        position: relative;
        margin: auto;
    }
    .txtonb1 {
        color: black ;
        position: relative;
        top: 53%;
        padding-left: 6em;
        padding-right: 6em; 
        text-align: center;
    }
    .txtonb2 {
        color: slategray ;
        position: relative;
        top: 55%;
        padding-left: 3em;
        padding-right: 3em; 
        text-align: center;
    }
}
@media screen and (min-width:1024px) {
    .pace-done{
       background-color: #E0E2E3;
    }
    .wizard-container .card-wizard.active {
        margin-top: -12px;        
    }
    .card-wizard .card-header {
        padding-bottom: 25px;
        padding-top: 8px;
        margin-left: -1px;
        margin-right: -1px;
        margin-top: -1px;
    }
    .clearfix {
        margin-bottom: 10px;
    }
    .image2 {
        background-position: center;
        overflow: hidden;
        height: 400px;
        width: 600px;
        position: relative;
        margin: auto;
    }
    .image3 {
        background-position: center;
        overflow: hidden;
        height: 50px;
        width: 244px;
        position: relative;
        margin: auto;
        margin-top: 70px;
        margin-bottom: 25px;
    }
    .txtonb1 {
        color: black ;
        position: relative;
        top: 8%;
        font-size: 18px;
        padding-left: 6em;
        padding-right: 6em; 
        text-align: center;
    }
    .txtonb2 {
        color: slategray ;
        position: relative;
        top: 13%;
        padding-left: 3em;
        padding-right: 3em; 
        text-align: center;
    }
    .icono{
        font-size: 3.3em;
        margin-left: 47%;
        margin-top: 10%;
        color: #18CE0F;
    }
    .monto{
        font-size: 2.5rem;
        text-align: center;
        font-weight: 300;
    }

}

